@mixin debug {
  border: 1px solid #FAA;
  background-color: #F6F6F6;
}

@mixin block {
  display: block;
  @include box-sizing(border-box);
}

@mixin break($where) {
  &:#{$where} { content:"\A"; white-space:pre; }
}

@mixin responsive($media, $min-range:0, $max-range:1) {

  @if $emulateRetina {

      @if $media == full {
          @media only screen and (min-width: 2500px) { @content; }
      }
      @else if $media == tablet {
          @media only screen and (min-width: 2500px) and (max-width: 2500px) { @content; }
      }
      @else if $media == phone {
          @media only screen and (min-width: 1px) and (max-width: 2200px) { @content; }
      }
      @else if $media == zero {
          @media only screen and (min-width: 1px) and (max-width: 2200px) { @content; }
      }
      @else if $media == small {
          @media only screen and (min-width: 1px) and (max-width: 2200px) { @content; }
      }
      @else if $media == notweb {
          @media only screen and (min-width: $break-zero) and (max-width: $break-full - 1) { @content; }
      }
      @else if $media == manual {
          @media only screen and (min-width: $min-range) and (max-width: $max-range - 1){ @content; }
      }

  }
  @else {

      @if $media == full {
          @media only screen and (min-width: $break-full) { @content; }
      }
      @else if $media == tablet {
          @media only screen and (min-width: $break-tablet) and (max-width: $break-full - 1) { @content; }
      }
      @else if $media == big {
          @media only screen and (min-width: $break-tablet) { @content; }
      }
      @else if $media == phone {
          @media only screen and (min-width: $break-phone) and (max-width: $break-tablet - 1) { @content; }
      }
      @else if $media == zero {
          @media only screen and (min-width: $break-zero) and (max-width: $break-phone - 1) { @content; }
      }
      @else if $media == small {
          @media only screen and (min-width: $break-zero) and (max-width: $break-tablet - 1) { @content; }
      }
      @else if $media == notweb {
          @media only screen and (min-width: $break-zero) and (max-width: $break-full - 1) { @content; }
      }
      @else if $media == manual {
          @media only screen and (min-width: $min-range) and (max-width: $max-range - 1){ @content; }
      }

  }

}

@mixin retina($filename, $width: "auto", $height: "auto", $position: 0 0) {
  @media  (min--moz-device-pixel-ratio: 1.3),
          (-o-min-device-pixel-ratio: 2.6/2),
          (-webkit-min-device-pixel-ratio: 1.3),
          (min-device-pixel-ratio: 1.3),
          (min-resolution: 1.3dppx) {
             @include bg($filename, $position);
             background-size: #{$width}px #{$height}px;
  }

  // width: @random()+"px";
}

@mixin navigation-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  > li{
      display: block;
      float: left;
      &:last-child {
          margin-right: 0px;
      }
  }
}

@mixin no-underline {
  text-decoration: none;
  &:hover { text-decoration: underline; }
}

@mixin bg($img, $position: 0 0, $repeat: no-repeat, $color: transparent, $attachment: scroll) {
  background: {
      color: $color;
      image: url(../../assets/images/#{$img});
      position: $position;
      repeat: $repeat;
      attachment: $attachment;
  }
}

@mixin image($url, $width, $height, $position: 0 0) {
  overflow: hidden;
  text-indent: -9000px;
  display: block;
  background: {
      image: url(../../assets/images/#{$url});
      position: $position;
      repeat: no-repeat;
  };
  width: #{$width}px;
  height: #{$height}px;
}

@mixin text-shadow-buttons {
  text-shadow: (rgba(#6C3113, 0.7) 1px 1px 0);
}

@mixin text-shadow-slideshow {
  text-shadow: (rgba(#fff, 1.0) 1px 1px 0);
}

@mixin text-shadow-white {
  text-shadow: (rgba(#fff, 1.0) 1px 1px 0);
}

@mixin print-rhythm {
  margin: 0 !important;
  padding: 0 $print-padding-horizontal $print-padding-vertical $print-padding-horizontal !important;
  width: auto !important;
  height: auto !important;
  line-height: 18px !important;
}

@mixin break($where) {
  &:#{$where} { content:"\A"; white-space:pre; }
}

// @mixin position($type: absolute, $top: 0, $left: 0, $bottom: auto, $right: auto, $zindex: auto) {
//     position: $type;
//     top:     if($top    == 'auto', $top,    #{$top}px);
//     left:    if($left   == 'auto', $left,   #{$left}px);
//     bottom:  if($bottom == 'auto', $bottom, #{$bottom}px);
//     right:   if($right  == 'auto', $right,  #{$right}px);
//     z-index: $zindex;
// }

// @mixin absolute($top: 0, $left: 0, $bottom: auto, $right: auto, $zindex: auto) {
//  @include position(absolute, $top, $left, $bottom, $right, $zindex);
// }

// @mixin size($width, $height) {
//     width: #{$width}px;
//     height: #{$height}px;
// }

@mixin position($type: absolute, $top: 0, $left: 0, $bottom: auto, $right: auto, $zindex: auto) {

  @if( $top != auto) { @if (unitless($top)) { $top: $top * 1px; } }
  @if( $left != auto) { @if (unitless($left)) { $left: $left * 1px; } }
  @if( $bottom != auto) { @if (unitless($bottom)) { $bottom: $bottom * 1px; } }
  @if( $right != auto) { @if (unitless($right)) { $right: $right * 1px; } }

  position: $type;
  top:     if($top    == 'auto', $top,    $top);
  left:    if($left   == 'auto', $left,   $left);
  bottom:  if($bottom == 'auto', $bottom, $bottom);
  right:   if($right  == 'auto', $right,  $right);
  @if( $zindex != 'auto' ) { z-index: $zindex; }
}

@mixin absolute($top: 0, $left: 0, $bottom: auto, $right: auto, $zindex: auto) {
  @include position(absolute, $top, $left, $bottom, $right, $zindex);
}

@mixin sizex($width, $height) {

  @if (unitless($width)) {
      $width: $width * 1px;
  }

  @if (unitless($height)) {
      $height: $height * 1px;
  }

  width: $width;
  height: $height;
}

//--------------------------------
// Photoshop Drop Shadow
//--------------------------------
@mixin photoshop-drop-shadow ($angle: 0, $distance: 0, $spread: 0, $size: 0, $color: #000, $inner: false) {
  $angle: (180 - $angle) * pi() / 180; // convert to radians
  $h-shadow: round(cos($angle) * $distance);
  $v-shadow: round(sin($angle) * $distance);
  $css-spread: $size * $spread/100;
  $blur: ($size - $css-spread);
  $inset: if($inner != false, 'inset', '');

  @include box-shadow($h-shadow $v-shadow $blur $css-spread $color unquote($inset));
}


//--------------------------------
// Photoshop Inner Shadow
//--------------------------------
@mixin photoshop-inner-shadow ($angle: 0, $distance: 0, $spread: 0, $size: 0, $color: #000) {
  @include photoshop-drop-shadow ($angle, $distance, $spread, $size, $color, true);
}

//--------------------------------
// Photoshop Text Shadow
//--------------------------------
@mixin photoshop-text-shadow ($angle: 0, $distance: 0, $spread: 0, $size: 0, $color: #000) {
  // NOTE: $spread has no effect for text shadows
  $angle: (180 - $angle) * pi() / 180;
  $h-shadow: round(cos($angle) * $distance);
  $v-shadow: round(sin($angle) * $distance);
  $css-spread: $size * $spread/100;
  $blur: ($size - $css-spread);

  @include text-shadow($h-shadow $v-shadow $blur $color);
}

// Vendor Prefixes
//
// All vendor mixins are deprecated as of v3.2.0 due to the introduction of
// Autoprefixer in our Gruntfile. They have been removed in v4.

// - Animations
// - Backface visibility
// - Box shadow
// - Box sizing
// - Content columns
// - Hyphens
// - Placeholder text
// - Transformations
// - Transitions
// - User Select


// Animations
@mixin animation($animation) {
  -webkit-animation: $animation;
       -o-animation: $animation;
          animation: $animation;
}
@mixin animation-name($name) {
  -webkit-animation-name: $name;
          animation-name: $name;
}
@mixin animation-duration($duration) {
  -webkit-animation-duration: $duration;
          animation-duration: $duration;
}
@mixin animation-timing-function($timing-function) {
  -webkit-animation-timing-function: $timing-function;
          animation-timing-function: $timing-function;
}
@mixin animation-delay($delay) {
  -webkit-animation-delay: $delay;
          animation-delay: $delay;
}
@mixin animation-iteration-count($iteration-count) {
  -webkit-animation-iteration-count: $iteration-count;
          animation-iteration-count: $iteration-count;
}
@mixin animation-direction($direction) {
  -webkit-animation-direction: $direction;
          animation-direction: $direction;
}
@mixin animation-fill-mode($fill-mode) {
  -webkit-animation-fill-mode: $fill-mode;
          animation-fill-mode: $fill-mode;
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden`

@mixin backface-visibility($visibility) {
  -webkit-backface-visibility: $visibility;
     -moz-backface-visibility: $visibility;
          backface-visibility: $visibility;
}

// Drop shadows
//
// Note: Deprecated `.box-shadow()` as of v3.1.0 since all of Bootstrap's
// supported browsers that have box shadow capabilities now support it.

@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
          box-shadow: $shadow;
}

// Box sizing
@mixin box-sizing($boxmodel) {
  -webkit-box-sizing: $boxmodel;
     -moz-box-sizing: $boxmodel;
          box-sizing: $boxmodel;
}

// CSS3 Content Columns
@mixin content-columns($column-count, $column-gap: $grid-gutter-width) {
  -webkit-column-count: $column-count;
     -moz-column-count: $column-count;
          column-count: $column-count;
  -webkit-column-gap: $column-gap;
     -moz-column-gap: $column-gap;
          column-gap: $column-gap;
}

// Optional hyphenation
@mixin hyphens($mode: auto) {
  word-wrap: break-word;
  -webkit-hyphens: $mode;
     -moz-hyphens: $mode;
      -ms-hyphens: $mode; // IE10+
       -o-hyphens: $mode;
          hyphens: $mode;
}

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
  // Firefox
  &::-moz-placeholder {
    color: $color;
    opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
  }
  &:-ms-input-placeholder { color: $color; } // Internet Explorer 10+
  &::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
}

// Transformations
@mixin scale($ratio...) {
  -webkit-transform: scale($ratio);
      -ms-transform: scale($ratio); // IE9 only
       -o-transform: scale($ratio);
          transform: scale($ratio);
}

@mixin scaleX($ratio) {
  -webkit-transform: scaleX($ratio);
      -ms-transform: scaleX($ratio); // IE9 only
       -o-transform: scaleX($ratio);
          transform: scaleX($ratio);
}
@mixin scaleY($ratio) {
  -webkit-transform: scaleY($ratio);
      -ms-transform: scaleY($ratio); // IE9 only
       -o-transform: scaleY($ratio);
          transform: scaleY($ratio);
}
@mixin skew($x, $y) {
  -webkit-transform: skewX($x) skewY($y);
      -ms-transform: skewX($x) skewY($y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
       -o-transform: skewX($x) skewY($y);
          transform: skewX($x) skewY($y);
}
@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
      -ms-transform: translate($x, $y); // IE9 only
       -o-transform: translate($x, $y);
          transform: translate($x, $y);
}
@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
          transform: translate3d($x, $y, $z);
}
@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
      -ms-transform: rotate($degrees); // IE9 only
       -o-transform: rotate($degrees);
          transform: rotate($degrees);
}
@mixin rotateX($degrees) {
  -webkit-transform: rotateX($degrees);
      -ms-transform: rotateX($degrees); // IE9 only
       -o-transform: rotateX($degrees);
          transform: rotateX($degrees);
}
@mixin rotateY($degrees) {
  -webkit-transform: rotateY($degrees);
      -ms-transform: rotateY($degrees); // IE9 only
       -o-transform: rotateY($degrees);
          transform: rotateY($degrees);
}
@mixin perspective($perspective) {
  -webkit-perspective: $perspective;
     -moz-perspective: $perspective;
          perspective: $perspective;
}
@mixin perspective-origin($perspective) {
  -webkit-perspective-origin: $perspective;
     -moz-perspective-origin: $perspective;
          perspective-origin: $perspective;
}
@mixin transform-origin($origin) {
  -webkit-transform-origin: $origin;
     -moz-transform-origin: $origin;
      -ms-transform-origin: $origin; // IE9 only
          transform-origin: $origin;
}


// Transitions

@mixin transition($transition...) {
  -webkit-transition: $transition;
       -o-transition: $transition;
          transition: $transition;
}
@mixin transition-property($transition-property...) {
  -webkit-transition-property: $transition-property;
          transition-property: $transition-property;
}
@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: $transition-delay;
          transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration...) {
  -webkit-transition-duration: $transition-duration;
          transition-duration: $transition-duration;
}
@mixin transition-timing-function($timing-function) {
  -webkit-transition-timing-function: $timing-function;
          transition-timing-function: $timing-function;
}
@mixin transition-transform($transition...) {
  -webkit-transition: -webkit-transform $transition;
     -moz-transition: -moz-transform $transition;
       -o-transition: -o-transform $transition;
          transition: transform $transition;
}
@mixin opacity($opacity) {
  opacity: $opacity;
  // IE8 filter
  $opacity-ie: ($opacity * 100);
  filter: alpha(opacity=$opacity-ie);
}

// WebKit-style focus

@mixin tab-focus() {
  // Default
  outline: thin dotted;
  // WebKit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}


// User select
// For selecting text on the page

@mixin user-select($select) {
  -webkit-user-select: $select;
     -moz-user-select: $select;
      -ms-user-select: $select; // IE10+
          user-select: $select;
}


// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 25%);
  }
  &:hover {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
          border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}

// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}
