@import "mixins";
@import "variables";
@import "fonts";
@import "buttons";

html, body {

  width: 100%;
  height: 100%;
}

body {

  margin: 0;
  padding: 0;
  background-color: #fff;

  *:focus { outline: none; }

  .bar {

    @include position(fixed, 0, 0);
    @include sizex(100%, 29);
    z-index: 1000;

    &.top {

      background: #6d2077;
    }

    &.bottom {

      height: 13px;
      top: auto;
      bottom: 0;
      background: #bea7ca;
    }
  }

  .guitar {

    display: none;
    @include image("bg.png", 960, 493);
    background-size: 960px 493px;
    @include absolute(460, 42%);
    margin-left: -405px;
  }

  .header {

    position: relative;
    padding-top: 29px;
    z-index: 100;

    .logo {

      @include absolute(62, 50%);
      margin-left: -110px;
      @include image("logo.png", 220, 105);
      background-size: cover;
      cursor: default;

      transition: top 0.5s, width 0.5s, height 0.5s, margin-left 0.5s;
      transform-origin: 50% 0;
    }

    .breadcrumbs {

      padding-top: 155px;
      text-align: center;

      transition: padding 0.5s;
      transform-origin: 50% 0;

      ul {

        margin: 0;
        padding: 0;

        li {

          margin: 0 12px;
          padding: 0;
          @include sizex(19, 19);
          @include bg("header-dots.png");
          background-size: 38px 19px;
          overflow: hidden;
          display: inline-block;

          &.unlocked {

            cursor: pointer;
          }

          &.item-1 {

            @include sizex(23, 29);
            @include bg("header-locks.png");
            background-size: 47px 29px;
          }

          &.item-8 {

            cursor: default;
          }
        }
      }
    }
  }

  .body {

    padding-bottom: 13px;

    .panel {

      position: relative;
      opacity: 1;
      -webkit-transition: opacity 250ms;
      transition: opacity 250ms;
      box-shadow: none;
      width: 900px;
      margin: 0 auto;
      background-color: transparent;

      .input {
        opacity: 1;
        animation: fadeinout 1s linear forwards;

        text-align: center;

        label {

          display: inline-block;
          width: 650px;
          margin: 0 auto;
          position: relative;
          left: 14px;

          input {

            @include font-bold();
            font-size: 32px;
            color: #b7b7b7;
            border: none;
            border-bottom: 2px solid #b7b7b7;
            // @include bg("icon-input-arrow-circle.png", 100% 12px);
            // background-size: 30px 30px;
            width: 100%;
            padding-bottom: 2px;
            padding-right: 36px;
            text-align: left;

            &:focus {
              outline: none;
            }

            &:focus::-webkit-input-placeholder { color:transparent; }
            &:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
            &:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
            &:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */

            &::-webkit-input-placeholder { color: #b7b7b7; } /* WebKit, Blink, Edge */
            &:-moz-placeholder { color: #b7b7b7; opacity: 1; } /* Mozilla Firefox 4 to 18 */
            &::-moz-placeholder { color: #b7b7b7; opacity: 1; } /* Mozilla Firefox 19+ */
            &:-ms-input-placeholder { color: #b7b7b7; } /* Internet Explorer 10-11 */

            &.error {

              border-bottom: 2px solid #7c2b7d;

              // &::-webkit-input-placeholder { color: #7c2b7d; } /* WebKit, Blink, Edge */
              // &:-moz-placeholder { color: #7c2b7d; opacity: 1; } /* Mozilla Firefox 4 to 18 */
              // &::-moz-placeholder { color: #7c2b7d; opacity: 1; } /* Mozilla Firefox 19+ */
              // &:-ms-input-placeholder { color: #7c2b7d; } /* Internet Explorer 10-11 */
            }

            &#photoFile {

              @include absolute(0, 0);
              @include sizex(1, 1);
              opacity: 0;
              visibility: hidden;
            }
          }
        }

        div.btn {

          @include position(relative, -4, -24);
          @include sizex(40, 40);
          border: none;
          background: transparent;
        }

        div.btn-arrow {
          @include bg("icon-input-arrow-circle.png", 50% 50%);
          background-size: 30px 30px;
        }
      }

      div.error {

        @include position(relative, 4, -6px);
        @include font-bold();
        font-size: 16px;
        color: #7c2b7d;
        width: 464px;
        margin: 0 auto;
        text-align: center;
      }

      .bracket-link {

        @include font-light();
        font-size: 20px;
        color: #6d2077;

        a {

          display: inline-block;
          padding: 0 7px;
          text-decoration: underline;
          color: #6d2077;

          &:hover {

            text-decoration: none;
          }
        }
      }
    }

    .card-left {

      @include absolute(335, auto, auto, 50%);
      @include bg("card-left.png");
      @include sizex(500, 355);
      background-size: cover;

      -webkit-box-shadow: 0px 9px 9px 0px rgba(200, 200, 200, 1);
      -moz-box-shadow: 0px 9px 9px 0px rgba(200, 200, 200, 1);
      box-shadow: 0px 9px 9px 0px rgba(200, 200, 200, 1);
    }

    .preview {


      @include absolute(335, 50%);
      @include sizex(565, 400);
      margin-left: -283px;
      background: #f4f4f4;

      // @include photoshop-drop-shadow(90, 24px, 3px, 27px, rgba(0,0,0,0.7));
      -webkit-box-shadow: 0px 9px 9px 0px rgba(200, 200, 200, 1);
      -moz-box-shadow: 0px 9px 9px 0px rgba(200, 200, 200, 1);
      box-shadow: 0px 9px 9px 0px rgba(200, 200, 200, 1);

      justify-content: center;
      align-items: center;

      transition: top 0.5s, margin-left 0.5s, transform 0.5s;

      .to {

        @include absolute(15, 15);
        @include font-light();
        font-size: 20px;

        span {

          @include font-light();
          font-size: 16px;
          line-height: 105%;
        }
      }

      .middle {

        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        .copy {

          float: left;
          @include bg("card-thank-you.png");
          @include sizex(295, 109);
          background-size: cover;
        }

        .image {

          float: left;
          max-width: 230px;
          overflow: hidden;

          img {

            border: solid 3px #000;
            max-width: 215px;
            max-height: 215px;
          }
        }
      }

      .from {

        @include absolute(auto, 15, 15, 15);
        @include font-light();
        font-size: 20px;
        text-align: center;

        span {

          @include font-light();
          font-size: 14px;
          display: block;
        }
      }
    }
  }

  // --------------------------------------------------------------------------------
  // state-specific breadcrumbs
  // --------------------------------------------------------------------------------

  &.state-1 .header .breadcrumbs ul li.item-1 { width: 19px; background-position: -23px 0; }
  &.state-2 .header .breadcrumbs ul li.item-2 { background-position: -19px 0; }
  &.state-3 .header .breadcrumbs ul li.item-3 { background-position: -19px 0; }
  &.state-4 .header .breadcrumbs ul li.item-4 { background-position: -19px 0; }
  &.state-5 .header .breadcrumbs ul li.item-5 { background-position: -19px 0; }
  &.state-6 .header .breadcrumbs ul li.item-6 { background-position: -19px 0; }
  &.state-7 .header .breadcrumbs ul li.item-7 { background-position: -19px 0; }
  &.state-8 .header .breadcrumbs ul li.item-8 { background-position: -19px 0; }

  // --------------------------------------------------------------------------------
  // state-specific bodies
  // --------------------------------------------------------------------------------

  &.state-1 {

    background-color: #fff;

    .bar.bottom {

      display: none;
    }

    .guitar {

      // display: block;
      display: none;
    }

    .header {

      position: relative;
      padding-top: 29px;
      z-index: 100;

      .logo {

        @include absolute(115, 50%);
        margin-left: -250px;
        @include image("logo.png", 499, 239);
        background-size: cover;
        cursor: pointer;
      }

      .breadcrumbs {

        padding-top: 30px;
      }
    }

    .body {

      .panel-1 {

        display: block;
        opacity: 1;

        padding-top: 290px;
        width: 860px;
        margin: 0 auto;

        .content {

          position: relative;
        }

        div.note {

          @include absolute(170, 187);
          @include font-light();
          font-size: 12px;
        }

        div.note--report {
          @include absolute(210, 187);
          @include font-light();
          font-size: 12px;
        }

        p {

          @include font-light();
          font-size: 22px;
          line-height: 125%;
          text-align: center;
          padding: 30px 0;
        }

        .input {

          position: relative;
          // left: 152px;

          left: 30px;
          padding: 9px 0 5px 46px;
          @include bg("icon-input-big-lock.png", 0 10px);
          background-size: 31px 48px;
          text-align: left;
          margin: 20px 0 0 10px;

          text-align: center;
          width: 770px;
          margin-left: auto;
          margin-right: auto;

          label {

            display: inline-block;
            position: static;
            left: auto;
          }
        }

        .error {

          @include position(relative, 0, 1);
          @include font-bold();
          font-size: 16px;
          color: #7c2b7d;
        }
      }
    }
  }

  // --------------------------------------------------------------------------------

  &.state-2 {

    .body {

      .panel-2 {

        padding-top: 175px;

        label {
          width: 550px;
        }
      }
    }
  }

  // --------------------------------------------------------------------------------

  &.state-3 {

    .body {

      .panel-3 {

        padding-top: 175px;

        .note {
          width: 650px;
          margin: 0 auto;
          text-align: center;
          position: relative;
          top: 5px;
          font-style: italic;
          font-size: 15px;
          color: #b7b7b7;
        }
      }
    }
  }

  // --------------------------------------------------------------------------------

  &.state-4 {

    .body {

      .panel-4 {

        padding-top: 25px;
      }
    }
  }

  // --------------------------------------------------------------------------------

  &.state-5 {

    .body {

      .panel-5 {

        padding-top: 25px;
      }
    }
  }

  // --------------------------------------------------------------------------------

  &.state-6 {

    .body {

      .panel-6 {

        padding-top: 25px;
        width: 680px;

        label {
          width: 500px;
          left: 19px;
        }

        .bracket-link {

          &.choose {

            @include absolute(80, 92);
          }

          &.skip {

            @include absolute(80, auto, auto, 92);
          }

          &.cancel {
            @include absolute(80, 92);
          }
        }

        .note {

          padding: 40px 0 0 0;
          width: 494px;
          margin: 0 auto;
          @include font-light();
          font-size: 14px;
          font-style: italic;
        }

        &.has-image {

          .bracket-link {

            &.choose { display: none; }
            &.skip   { display: none; }
            &.cancel { display: block;}
          }
        }
      }
    }

    .preview {

      top: 485px;
    }
  }

  // --------------------------------------------------------------------------------

  &.state-7 {

    .body {

      .panel-7 {

        width: 1010px;
        padding-top: 500px;

        .bracket-link.edit {

          @include absolute(20, 50%);
          width: 100px;
          text-align: center;
          margin-left: -50px;
          color: #d01d23;

          a {
            color: #d01d23;
          }
        }

        .input {

          input {

            text-align: center;
            background-image: none;
          }
        }

        .copy {

          padding-top: 20px;

          p {

            width: 520px;
            text-align: center;
            margin: 0 auto;
            padding: 0 5px;
            @include font-light();
            font-size: 16px;
            font-style: italic;
          }
        }

        .btn-red {

          margin: 15px auto;
          padding: 20px 120px;
          background: #6d2077;
          @include font-bold();
          font-size: 23px;
          color: #fff;
          width: auto;
          text-transform: uppercase;
        }

        .btn-grey {

          background: #888;
        }
      }
    }
  }

  // --------------------------------------------------------------------------------

  &.state-8 {

    .body {

      .panel-8 {

        width: 1010px;
        padding-top: 500px;

        h3 {

          @include font-bold();
          font-size: 58px;
          color: #3a3939;
          text-align: center;
          margin: 0;
          padding: 0 0 15px 0;
        }

        p {

          @include font-bold();
          font-size: 23px;
          color: #8e8e8e;
          text-align: center;
          margin: 0;
          padding: 00;
        }

        .bracket-link.again {

          text-align: center;
          font-size: 18px;
          padding-top: 10px;
          color: #d01d23;

          a {
            color: #d01d23;
          }
        }
      }
    }
  }

  // --------------------------------------------------------------------------------
  // full-card preview
  // --------------------------------------------------------------------------------

  &.state-7,
  &.state-8,
  &.view {

    .body {

      .card-left {

        top: 295px;

        transform: perspective(1000px) rotateY(20deg) scale(0.9, 1.1);
        transform-origin: 100% 50%;
      }

      .preview {

        top: 272px;
        transform: perspective(1000px) rotateY(-20deg) scale(0.8, 0.975);
        transform-origin: 0 50%;
        margin-left: 0;
      }
    }
  }

  // --------------------------------------------------------------------------------
  // full-card preview
  // --------------------------------------------------------------------------------

  &.state-4,
  &.state-5,
  &.state-6,
  &.state-7,
  &.state-8,
  &.view {

    .body {

      .preview {

        display: flex;
      }
    }
  }

  // --------------------------------------------------------------------------------
  // VIEW SCREEN
  // --------------------------------------------------------------------------------

  &.view {

    .body {

      .card-left {

        top: 145px;
      }

      .preview {

        top: 122px;
      }
    }
  }
}

.glogo {
  position: absolute;
  width: 140px;
  height: 36px;
  bottom: 3vh;
  right: 10vh;
  background: url(../../assets/images/glogo.png) top left no-repeat;
  background-size: 140px 36px;
}

.glogo-left {
  position: absolute;
  height: 36px;
  width: 300px;
  bottom: 15vh;
  left: 10vh;
  font-size: 20px;
}


@media screen and ( min-height: 943px ) { body .guitar { top: auto; bottom: 0; } }

@-webkit-keyframes fadeinout {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeinout {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@media screen and ( min-height: 943px ) { body .guitar { top: auto; bottom: 0; display: none; } }