$font-stack: "Helvetica Neue", "HelveticaNeue", Arial, sans-serif;

@mixin font-light() {

	font-family: "montserratlight", $font-stack;
	// font-weight: 100;
}

@mixin font-normal() {

	font-family: "montserratregular", $font-stack;
	// font-weight: 400;
}

@mixin font-bold() {

    font-family: "montserratbold", $font-stack;
    // font-weight: 700;
}

@mixin font-heavy() {

	font-family: "montserratblack", $font-stack;
	// font-weight: 900;
}

// ------------------------------------------------------------------------------------------------

@font-face {
    font-family: 'montserratblack';
    src: url('../../assets/fonts/montserrat-black-webfont.eot');
    src: url('../../assets/fonts/montserrat-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/montserrat-black-webfont.woff2') format('woff2'),
         url('../../assets/fonts/montserrat-black-webfont.woff') format('woff'),
         url('../../assets/fonts/montserrat-black-webfont.ttf') format('truetype'),
         url('../../assets/fonts/montserrat-black-webfont.svg#montserratblack') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratbold';
    src: url('../../assets/fonts/montserrat-bold-webfont.eot');
    src: url('../../assets/fonts/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/montserrat-bold-webfont.woff2') format('woff2'),
         url('../../assets/fonts/montserrat-bold-webfont.woff') format('woff'),
         url('../../assets/fonts/montserrat-bold-webfont.ttf') format('truetype'),
         url('../../assets/fonts/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratextra_bold';
    src: url('../../assets/fonts/montserrat-extrabold-webfont.eot');
    src: url('../../assets/fonts/montserrat-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/montserrat-extrabold-webfont.woff2') format('woff2'),
         url('../../assets/fonts/montserrat-extrabold-webfont.woff') format('woff'),
         url('../../assets/fonts/montserrat-extrabold-webfont.ttf') format('truetype'),
         url('../../assets/fonts/montserrat-extrabold-webfont.svg#montserratextra_bold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserrathairline';
    src: url('../../assets/fonts/montserrat-hairline-webfont.eot');
    src: url('../../assets/fonts/montserrat-hairline-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/montserrat-hairline-webfont.woff2') format('woff2'),
         url('../../assets/fonts/montserrat-hairline-webfont.woff') format('woff'),
         url('../../assets/fonts/montserrat-hairline-webfont.ttf') format('truetype'),
         url('../../assets/fonts/montserrat-hairline-webfont.svg#montserrathairline') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratlight';
    src: url('../../assets/fonts/montserrat-light-webfont.eot');
    src: url('../../assets/fonts/montserrat-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/montserrat-light-webfont.woff2') format('woff2'),
         url('../../assets/fonts/montserrat-light-webfont.woff') format('woff'),
         url('../../assets/fonts/montserrat-light-webfont.ttf') format('truetype'),
         url('../../assets/fonts/montserrat-light-webfont.svg#montserratlight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratregular';
    src: url('../../assets/fonts/montserrat-regular-webfont.eot');
    src: url('../../assets/fonts/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/montserrat-regular-webfont.woff2') format('woff2'),
         url('../../assets/fonts/montserrat-regular-webfont.woff') format('woff'),
         url('../../assets/fonts/montserrat-regular-webfont.ttf') format('truetype'),
         url('../../assets/fonts/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratsemi_bold';
    src: url('../../assets/fonts/montserrat-semibold-webfont.eot');
    src: url('../../assets/fonts/montserrat-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/montserrat-semibold-webfont.woff2') format('woff2'),
         url('../../assets/fonts/montserrat-semibold-webfont.woff') format('woff'),
         url('../../assets/fonts/montserrat-semibold-webfont.ttf') format('truetype'),
         url('../../assets/fonts/montserrat-semibold-webfont.svg#montserratsemi_bold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratultra_light';
    src: url('../../assets/fonts/montserrat-ultralight-webfont.eot');
    src: url('../../assets/fonts/montserrat-ultralight-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/montserrat-ultralight-webfont.woff2') format('woff2'),
         url('../../assets/fonts/montserrat-ultralight-webfont.woff') format('woff'),
         url('../../assets/fonts/montserrat-ultralight-webfont.ttf') format('truetype'),
         url('../../assets/fonts/montserrat-ultralight-webfont.svg#montserratultra_light') format('svg');
    font-weight: normal;
    font-style: normal;

}